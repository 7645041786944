<template>
	<div class="all_detail">
		<div class="detail">
			<div class="person_infor">
				<div class="login_top">
					<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
					<div class="login_title" @click="backClick">{{ $t('talent') }}</div>
				</div>
				<div class="tip">
					<img src="@/assets/mycenter/jinwei.png" alt="">
					<span>
						{{ $t('talentHireTitle') }}
					</span>
				</div>
				<div class="all_list">
					<!-- <div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{ $t('name') }}
						</div>
						<div class="list_item_bottom">
							<el-input v-model="name" :placeholder="$t('inputName')" type="text" clearable>
							</el-input>
						</div>
					</div> -->
					<!-- <div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{ $t('my.sex') }}
						</div>
						<div class="list_item_bottom">
							<el-radio-group v-model="sex" fill="##F39800" text-color="#F39800">
								<el-radio :label="1">{{ $t('other.man') }}</el-radio>
								<el-radio :label="2">{{ $t('other.women') }}</el-radio>
							</el-radio-group>
						</div>
					</div> -->

					<!-- 岗位选择 -->
					<div class="list_item">
						<div class="list_item_top">
							{{ $t('postType') }}
						</div>
						<div class="list_item_bottom">
							<el-select v-model="work_id" :placeholder="$t('release.pleaseSelect')" clearable>
								<el-option v-for="item in workList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
					</div>

					<!-- <div class="list_item">
						<div class="list_item_top">
							<span>*</span>WhatsApp
						</div>
						<div class="list_item_bottom">
							<el-input :placeholder="$t('inputWhatsApp')" type="text" clearable v-model="whatsapp">
							</el-input>
						</div>
					</div> -->
					<!-- <div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{ $t('email') }}
						</div>
						<div class="list_item_bottom">
							<el-input :placeholder="$t('login.inputEmail')" type="text" clearable v-model="email">
							</el-input>
						</div>
					</div> -->
					<!-- <div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{ $t('residentialAddress') }}
						</div>
						<div class="list_item_bottom">
							<el-input :placeholder="$t('inputResidentialAddress')" type="text" clearable v-model="addr">
							</el-input>
						</div>
					</div> -->


					<!-- <div class="infor_item_right">
						<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
							:show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl" :src="imageUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div> -->

					<!-- 上传图片 -->
					<div class="list_item" style="border-bottom: none;">
						<div class="list_item_top">
							{{ $t('resumeUpload') }}
						</div>
						<div class="infor_item_right">
							<el-upload :action="uploadImgUrl" :show-file-list="false" list-type="picture-card"
								:on-success="handleAvatarSuccess3" :before-upload="beforeAvatarUpload">
								<div class="upload_img" @mouseenter="mouseenterClick3" @mouseleave="mouseleaveClick3">
									<img v-if="imageUrl" :src="imageUrl" class="avatar">
									<span v-if="imageUrl && isShowDeleteIcon_gongsi" class="el-upload-action"
										@click.stop="handleRemove()">
										<i class="el-icon-delete"></i>
									</span>
									<i v-if="!imageUrl" slot="default" class="el-icon-plus"></i>
								</div>


							</el-upload>
						</div>
					</div>

					<!-- <div class="list_item" style="border-bottom: none;">
						<div class="list_item_top">
							<span>*</span>{{ $t('resumeUpload') }}
						</div>
						<div class="list_item_bottom">
							<div class="all_imgs">
								<el-upload class="upload-demo" ref="uploadImg" :action="uploadImgUrl"
									:on-preview="handlePictureCardPreview" :on-remove="handleRemove"
									:before-upload="beforeUpload" :on-change="onChange" :data="{ dir: 'file' }">
									<el-button size="small" type="primary">{{ $t('clickUpload') }}</el-button>
									<div slot="tip" class="el-upload__tip">{{ $t('onlyUploadPDFAndImg') }}</div>
								</el-upload>
							</div>
						</div>
					</div> -->
					<div class="btn" @click="submitClick">
						<div>{{ $t('submit') }}</div>
					</div>
				</div>
			</div>
		</div>


		<div class="success_dialog">
			<el-dialog title="" :visible.sync="releaseSuccessVisible" width="30%">
				<div class="release_success">
					<div class="success_img">
						<img src="@/assets/mycenter/release_success.png">
					</div>
					<div class="success_title">
						{{ $t('yourResumeHasBeenSubmittedSuccessfully') }}
					</div>
					<div class="success_tip">{{ $t('thePlatformStaffWillContactYouAfterAdmission') }}</div>
					<button class="btnHome" @click="gohome">{{ $t('goHome') }}</button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
	uploadImgUrl
} from '@/common/utils/config.js';

export default {
	data() {
		return {
			uploadImgUrl: uploadImgUrl,
			dialogImageUrl: '', //预览图片地址
			img_list: [], //上传图片列表
			previewImgDialog: false,

			name: '', //

			releaseSuccessVisible: false, //发布成功弹框

			workList: [], //职位列表
			work: '', //职位名称
			work_id: '', //职位ID
			name: '', //姓名
			sex: 1, //
			sex_name: '',
			whatsapp: '',
			email: '',
			addr: '', //居住地址
			imageUrl: '',//简历图片
			imageUrl_id:'',


			isFlag: false,

			isShowDeleteIcon_gongsi: false

		}
	},
	mounted() {
		this.getWorkList();
	},
	methods: {
		gohome(){
			this.$router.push({
				path: "/",
			})
		},
		// 获取职位
		getWorkList() {
			this.$http.workList().then(res => {
				if (res.code == 1) {
					this.workList = res.data;
				} else {
					this.$message.info(res.msg);
				}
			})
		},
	
		backClick() {
			this.$router.back();
		},
		// 图片删除
		handleRemove(file) {
			console.log(file, 3);
			this.imageUrl = '';
			console.log(this.imageUrl, '删除成功');

		},

		//鼠标进入事件
		mouseenterClick3() {
			if (this.imageUrl) {
				this.isShowDeleteIcon_gongsi = true;
			}
		},
		// 鼠标离开事件
		mouseleaveClick3() {
			if (this.imageUrl) {
				this.isShowDeleteIcon_gongsi = false;
			}
		},

		handleAvatarSuccess3(res, file) {
			console.log('上传成功');
			this.imageUrl = file.response.data[0].path;
			this.imageUrl_id = file.response.data[0].id;
			console.log(file);
			console.log(this.imageUrl, '上传成功');
		},

		// 上传之前
		beforeAvatarUpload(file) {
			console.log("jhhhh");
			const fileName = file.name
			const fileType = fileName.substring(fileName.lastIndexOf('.'));
			console.log("文件类型", fileType);

			if (
				fileType === '.jpg' ||
				fileType === '.png' ||
				fileType === '.jpeg' ||
				fileType === '.bmp' ||
				fileType === '.gif'
			) {

			} else {
				this.$message.error(this.$t('pleaseUploadTheCorrectImageType'))
				return false;
			}
		},

		// 上传图片成功
		onChange(file, fileList) {
			console.log(file);
			console.log(fileList);

			this.img_list = fileList;

		},
		// 提交
		submitClick() {
		
			if (this.isFlag) return;

			// let image = this.img_list.map(item => {
			// 	if (Object.prototype.hasOwnProperty.call(item, "response")) {
			// 		if (item.response.code == 1) {
			// 			return item.response.data[0].id;
			// 		}
			// 	}
			// }).join(',');

			// if (!this.name) {
			// 	console.log(1);
			// 	this.$message.error(this.$t('completeInfor'));
			// 	return;
			// }
			// if (!this.sex) {
			// 	console.log(2);

			// 	this.$message.error(this.$t('completeInfor'));
			// 	return;
			// }
			// if (!this.whatsapp) {
			// 	console.log(3);
				
			// 	this.$message.error(this.$t('completeInfor'));
			// 	return;
			// }
			// if (!this.email) {
			// 	console.log(4);

			// 	this.$message.error(this.$t('completeInfor'));
			// 	return;
			// }


			// if (!this.addr) {
			// 	console.log(5);

			// 	this.$message.error(this.$t('completeInfor'));
			// 	return;
			// }
			if(!this.work_id) {
				this.$message.error(this.$t('choosePostType'));
				return;
			}

			if (!this.imageUrl) {
				this.$message.error(this.$t('pleaseUploadResume'));
				return;
			}

			this.isFlag = true;

			let data = {
				// name: this.name, //姓名
				// sex: this.sex, //
				// whatsapp: this.whatsapp,
				// email: this.email,
				// addr: this.addr,
				work_id: this.work_id,
				image: this.imageUrl_id,
			}
			console.log(data);
			
			this.$http.addUserWork(data).then(res => {
				if (res.code == 1) {
					this.releaseSuccessVisible = true;
					this.isFlag = false;
					this.clearData();
				} else {
					this.isFlag = false;
					this.$message.error(res.msg);
				}
			})
		},
		// 清除数据
		clearData() {
			this.name = '';
			this.sex = 1;
			this.work_id = '';
			this.whatsapp = '';
			this.email = '';
			this.addr = '';
			this.$refs.uploadImg.clearFiles();
		},
	}
}
</script>

<style scoped="" lang="less">
.tip {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	margin: 32px 0 0 50px;
	width: 830px;
	height: 48px;
	// background: rgba(230, 0, 18, 0.09);
	font-size: 14px;
	padding-left: 40px;
	text-align: left;
	// color: #F39800;
	// background-image: url('../../assets/mycenter/zhaopin_back.png');
	background: repeating-linear-gradient(to right, rgba(45, 145, 255, 0.1), rgba(45, 145, 255, 0));


	img {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
}

.all_detail {
	background: #F6F7F9;
	// padding: 24px 0 64px;

}

.detail {
	box-sizing: border-box;
	// width: 1200px;
	margin: 0 auto;
	min-height: 600px;
	background-color: #FFFFFF;
}

.person_infor {
	// width: 1200px;
}


.login_top {
	display: flex;
	align-items: center;
	padding: 24px 40px;
	border-bottom: 1px solid #F3F5F6;


	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;

		&:hover {
			cursor: pointer;
		}
	}

	.front_img {
		display: flex;
		align-items: center;
		margin: 0 12px;

		img {
			width: 8px;
			height: 14px;
		}
	}
}

.all_list {
	margin: 0 50px;
	padding-top: 24px;
	padding-bottom: 60px;
}

.list_item {
	width: 824px;
	text-align: left;
	padding: 24px 0;
	// border-bottom: 1px solid #F3F5F6;
	font-size: 16px;


	&:last-child {
		border-bottom: none;
	}

	.list_item_top {
		span {
			color: #F39800;
		}
	}


	.list_item_bottom {

		margin-top: 8px;
		color: #4177DD;

		/deep/ .el-input__inner {
			width: 800px;
			height: 48px;
			border: 1px solid #CCCCCC;
			border-radius: 4px;
		}
	}
}

.btn {
	cursor: pointer;
	margin-top: 74px;
	text-align: left;

	div {
		text-align: center;
		display: inline-block;
		font-size: 16px;
		color: #FFFFFF;
		width: 300px;
		height: 48px;
		line-height: 48px;
		background: rgba(243, 152, 0);
		opacity: 1;
		border-radius: 4px;
	}

}

.success_dialog {


	/deep/ .el-dialog__body {
		// width: 480px;
		// height: 436px;
		border-radius: 8px;
		overflow-y: hidden;
	}
}

.release_success {
	padding-top: 56px;
	width: 100%;
	height: 416px;
	box-sizing: border-box;


	.success_img {
		img {
			width: 88px;
			height: 88px;
		}
	}

	.success_title {
		margin: 32px 0;
		font-weight: bold;
		color: #333333;
		font-size: 20px;
	}



	.success_tip {
		margin: 0 auto;
		color: #666666;
		width: 404px;
		word-break: break-all;
	}

	.btnHome{
		margin-top: 50px;
		border: none;
		border-radius: 10px;
		background-color: red;
		width: 100px;
		height: 40px;
		color: #fff;
	}


}

.infor_item_right {
	margin-top: 10px;

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 180px;
		height: 180px;
		line-height: 180px;
		text-align: center;
		border: 1px dashed #666;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
}

.el-upload-action {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	font-size: 0;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.el-upload-action:hover {
	font-size: 20px;
	background-color: #000;
	background-color: rgba(0, 0, 0, .3);
}

.upload_img {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	img {
		width: 110px;
		height: 110px;
	}
}

/deep/.el-upload--picture-card {
	display: flex;
	justify-content: center;
}
</style>
